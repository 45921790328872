<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
      <dark-Toggler class=" d-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <div
        v-if="isLogin"
        class="mx-2 accountId"
      >
        <span>{{ $t('accountId',{brand_name:getBrandName()}) }}</span>
        <span>:</span>
        <span>{{ isLogin }}</span>
      </div>
      <MessagesNav />
      <locale />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ fullName }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/0.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>

            <router-link :to="{ name: 'profile-general'}">{{ $t('profile') }}</router-link>
          </span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="submitLogout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { mapActions } from 'vuex'
import useAppConfig from '@core/app-config/useAppConfig'
import ToastificationMessageContent from '@/layouts/components/ToastificationMessageContent.vue'
import Locale from './Locale.vue'
import MessagesNav from './Messaging.vue'

let client

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Locale,
    DarkToggler,
    MessagesNav,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      profileLink: '',
      sseClient: null,
    }
  },

  computed: {
    fullName() {
      return `${this.$store.state.auth.user.first_name
        ? this.$store.state.auth.user.first_name
        : ''} 
      ${this.$store.state.auth.user.last_name
    ? this.$store.state.auth.user.last_name
    : ''}`
    },
    isLogin() {
      return this.$store.state.auth.user.id
    },
  },
  watch: {
    isLogin(val) {
      if (val) {
        this.sseClient.polyfillOptions.headers.authorization = localStorage.getItem('token')
        this.sseClient.connect()
      } else {
        this.sseClient.polyfillOptions.headers.authorization = null
        this.sseClient.disconnect()
      }
    },
  },

  async created() {
    this.profileLink = this.$i18n.locale
  },

  async mounted() {
    await this.disconnect()

    client = this.$sse.create()
    client.on('message', this.handleMessaging)
    this.sseClient = client

    if (this.isLogin) {
      this.sseClient.polyfillOptions.headers.authorization = localStorage.getItem('token')
      this.sseClient.connect()
    }
  },

  methods: {
    ...mapActions(['logout']),
    ...mapActions('Messaging', ['openMessageModal', 'setUnreadMessages', 'setEvent']),
    async submitLogout() {
      const response = await this.logout(this.form)
      if (response.status === 200) {
        this.$router.push(`/${this.$i18n.locale}/login?redirect=${this.$route.path}`)
      }
    },

    handleMessaging(message) {
      if (message) {
        const messageObject = JSON.parse(message)

        if (messageObject.event) this.setEvent(messageObject.event)

        this.$toast({
          component: ToastificationMessageContent,
          props: {
            ...messageObject,
            toastMessageOnClick: id => { this.openMessageModal(id) },
          },
        },
        {
          position: 'bottom-right',
          transition: 'Vue-Toastification__bounce',
        })
        // TODO : can get all messages when user stay in message page but not good idea
        // if (this.$route.name === 'messages') this.setAllMessages()
        this.setUnreadMessages()
      }
    },

    disconnect() {
      if (client) {
        client.disconnect()
        client = null
      }
    },
  },

  async beforeUnmount() {
    await this.disconnect()
  },
  setup() {
    const { skin } = useAppConfig()
    const darkLight = localStorage.getItem('vuexy-skin')
    if (darkLight) {
      skin.value = darkLight
    }
  },
}
</script>

<style scoped>
@media screen and (max-width:460px) {
  .bookmark-wrapper{
    justify-content: center;
    margin:unset;
  }

  .accountId{
    flex-basis: 100%;
  }
}
@media screen and (min-width:460px) and (max-width:1200px){
  .bookmark-wrapper{
    margin: 0 2rem;
  }
}
@media screen and (max-width:800px){
  .powered_by_cryptoforce{
    display: none !important;
  }
}
@media screen and (max-width:768px){
  .accountId{
    display: none;
  }
}
</style>
