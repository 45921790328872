<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="$i18n.locale = localeObj.locale"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      let language = this.$i18n.locale
      if (!this.locales.find(l => l.locale === this.$i18n.locale)) {
        language = 'en'
      }
      let dir = 'ltr'
      if (language === 'fa' || language === 'ar') {
        dir = 'rtl'
      }
      document.documentElement.setAttribute('dir', this.$store.state.appConfig.layout.isRTL = dir)

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.$router.push({
        params: { lang: language },
      }).catch(() => {})
      return this.locales.find(l => l.locale === language)
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'fa',
        img: require('@/assets/images/flags/fa.png'),
        name: 'Persian',
      },
      // {
      //   locale: 'cs',
      //   img: require('@/assets/images/flags/cs.png'),
      //   name: 'Czechia',
      // },
      // {
      //   locale: 'ar',
      //   img: require('@/assets/images/flags/AR.png'),
      //   name: 'Arabic',
      // },
      // {
      //   locale: 'cn',
      //   img: require('@/assets/images/flags/CN.png'),
      //   name: 'China',
      // },
      // {
      //   locale: 'ru',
      //   img: require('@/assets/images/flags/RU.png'),
      //   name: 'Russia',
      // },
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
}
</script>

<style>

</style>
