<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language pa-1"
    right
  >
    <template
      #button-content
    >
      <div class="position-relative">
        <feather-icon
          icon="BellIcon"
          size="26"
        />
        <div
          v-if="totalUnreadMessages > 0"
          class="unreadMessageNumber"
        >
          <span v-if="totalUnreadMessages < 10">{{ totalUnreadMessages }}</span>
          <span v-else>+9</span>
        </div>
      </div>
    </template>
    <b-dropdown-item
      v-for="messageObj in unReadMessages"
      :key="messageObj.id"
      style="min-width: 300px;"
      :class="!messageObj.is_read ? 'unreadMsssage' : ''"
      @click="()=> openModal(messageObj.id)"
    >
      <feather-icon
        icon="MailIcon"
        size="16"
      />
      <span
        v-if="messageObj.message.length < 30"
        class="ml-50"
      >
        {{ messageObj.message }}
      </span>
      <span
        v-else
        class="ml-50"
      >
        {{ messageObj.message.slice(0,29) + '...' }}
      </span>
    </b-dropdown-item>
    <hr v-if="unReadMessages.length !== 0">
    <b-dropdown-item>
      <b-button
        block
        variant="primary"
        style="font-size: .8rem; min-width: 200px;"
        @click="goToMessages"
      >
        {{ $t('all_messages') }}
      </b-button>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BButton,
  },

  computed: {
    unReadMessages() {
      return this.$store.state.Messaging.unReadMessages
    },
    totalUnreadMessages() {
      return this.$store.state.Messaging.totalUnreadMessages
    },
  },

  created() {
    this.setUnreadMessages()
  },

  methods: {
    ...mapActions('Messaging', ['openMessageModal', 'setUnreadMessages']),
    goToMessages() {
      this.$router.push(`/${this.$i18n.locale}/messages`)
    },
    openModal(id) {
      this.openMessageModal(id)
    },
  },
}
</script>

<style >
.unreadMessageNumber {
  position: absolute;
  top: -.6rem;
  left: -.6rem;
  background-color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px ;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: .8rem;
}
.unreadMsssage {
  position: relative;
}
.unreadMsssage > * {
  color: #000;
  font-weight: 700 !important;
}
.unreadMsssage::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%;
  transform: translateY(-50%);
}
</style>
